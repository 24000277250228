import React, { Fragment, useState, useEffect } from 'react'
import axioInstance from '../axiosInstance';
import { inWords } from '../global';
//import formatAmount from 'indian-currency-formatter';
import EditFamily from './EditFamily/EditFamily';

export default function DashboardRightFamilyGrid() {

    // eslint-disable-next-line
    const [childs, setChilds] = useState([]);

    // eslint-disable-next-line
    const [self, setSelf] = useState([]);
    // eslint-disable-next-line
    const [spouse, setSpouse] = useState([]);
    const [dependents, setDependents] = useState([]);

    const [isActive, setActive] = useState(false);
    const [profileData, setProfileData] = useState([]);
    const toggleClass = (data) => {
        setProfileData(data);
        setActive(true);
    };

    // eslint-disable-next-line
    const [selfGoalsCnt, setSelfGoalsCnt] = useState(0);
    // eslint-disable-next-line
    const [spouseGoalsCnt, setSpouseGoalsCnt] = useState(0);


    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');

        axioInstance.post(`get-family-dashboard`, { profile_id: profile_id }).then(
            (response, data) => {


                setChilds(response.data.get_child_details);

                let self = response.data.get_profile_details.find(product => product.relation === "self");
                setSelf(self);

                let spouse = response.data.get_profile_details.find(product => product.relation === "spouse");
                setSpouse(spouse ? spouse : false);


                setDependents(response.data.get_dependents.length > 0 ? response.data.get_dependents : []);


                let selfG = response.data.get_goals_count.find(product => product.relation === "self");
                setSelfGoalsCnt(selfG ? selfG.goal_count : false);

                let spouseG = response.data.get_goals_count.find(product => product.relation === "spouse");
                setSpouseGoalsCnt(spouseG ? spouseG.goal_count : false);

            });

    }, []);



    return (
        <Fragment>
            <div className="familycard_container">
                <div className="familycardtabs">
                    <div className="db_cashflow_outer pt20 pl30 pr30">
                        <ul className="nav nav-pills mb-3 font12 fw500 color353" id="pills-tab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="pills-Members-tab" data-toggle="pill" href="#pills-Members" role="tab" aria-controls="pills-Members" aria-selected="true" onClick={() => toggleClass([])}>Add Members</a>
                            </li>
                        </ul>
                        <div className="tab-content pt10" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-Members" role="tabpanel" aria-labelledby="pills-Members-tab">
                                <div className="membercharthdr font12 fw500 color949">
                                    <div className="membercharthdr_inner">Member</div>
                                    <div className="membercharthdr_inner">Relation</div>
                                    <div className="membercharthdr_inner">Age</div>
                                    <div className="membercharthdr_inner">Net worth</div>
                                    <div className="membercharthdr_inner">Income</div>
                                    <div className="membercharthdr_inner">Goals</div>
                                </div>

                                <div className="mt15 font10 fw500 color9ba memberchart_title pb5">PLANNERS</div>
                                <div className="membercharthdr font12 fw500 color182 pt15">
                                    <div className="membercharthdr_inner pointer" onClick={() => toggleClass(self)}>{self.full_name} <span className="bluebadge ml5" /></div>
                                    <div className="membercharthdr_inner">Self</div>
                                    <div className="membercharthdr_inner">{self.age} yrs</div>
                                    <div className="membercharthdr_inner">{inWords(self.income_self)}</div>
                                    <div className="membercharthdr_inner">{self.income_self > 0 ? ((self.income_self / self.total) * 100).toFixed(2) : '0'}%</div>
                                    <div className="membercharthdr_inner">{selfGoalsCnt ? selfGoalsCnt : '0'}</div>
                                </div>

                                {
                                    spouse ? (
                                        <>
                                            <div className="membercharthdr font12 fw500 color182 pt15">
                                                <div className="membercharthdr_inner pointer" onClick={() => toggleClass(spouse)}>{spouse.full_name} <span className="goldbadge ml5" /></div>
                                                <div className="membercharthdr_inner">Spouse</div>
                                                <div className="membercharthdr_inner">{spouse.age} yrs</div>
                                                <div className="membercharthdr_inner">{inWords(spouse.income_spouse)}</div>
                                                <div className="membercharthdr_inner">{spouse.income_spouse > 0 ? ((spouse.income_spouse / self.total) * 100).toFixed(2) : '0'}%</div>
                                                <div className="membercharthdr_inner">{spouseGoalsCnt ? spouseGoalsCnt : '0'}</div>
                                            </div>
                                        </>
                                    ) : ''
                                }



                                {
                                    childs.length > 0 && (<li>
                                        <div className="mt15 font10 fw500 color9ba memberchart_title pb5">KIDS</div>

                                        {
                                            childs.map((data, index) => {
                                                return (
                                                    <>
                                                        <div className="membercharthdr font12 fw500 color182 pt15">
                                                            <div className="membercharthdr_inner pointer" onClick={() => toggleClass(data)}>{data.first_name} </div>
                                                            <div className="membercharthdr_inner">{data.relation}</div>
                                                            <div className="membercharthdr_inner">{data.age > 0 ? data.age + ' y' : data.age_month + ' m'}</div>
                                                            <div className="membercharthdr_inner">--</div>
                                                            <div className="membercharthdr_inner">--</div>
                                                            <div className="membercharthdr_inner">{data.goal_count}</div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                        }


                                    </li>)
                                }


                                {
                                    dependents.length > 0 ? (

                                        <>

                                            <div className="mt15 font10 fw500 color9ba memberchart_title pb5">DEPENDENTS</div>
                                            {
                                                dependents.map((data, index) => {
                                                    return (
                                                        <>
                                                            <div className="membercharthdr font12 fw500 color182 pt15">
                                                                <div className="membercharthdr_inner">{data.full_name} </div>
                                                                <div className="membercharthdr_inner">{data.relation}</div>
                                                                <div className="membercharthdr_inner">{data.age > 0 ? data.age + ' y' : data.age_month + ' m'}</div>
                                                                <div className="membercharthdr_inner">--</div>
                                                                <div className="membercharthdr_inner">--</div>
                                                                <div className="membercharthdr_inner">{data.goal_count}</div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }

                                        </>
                                    ) : ''
                                }

                            </div>


                        </div>
                    </div>
                </div>
            </div>

            {
                isActive === true && (<EditFamily toggleClass={toggleClass} setActive={setActive} profileData={profileData} />)
            }
        </Fragment>
    )
}
