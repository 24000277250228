import React, { Fragment, useEffect, useState } from 'react'
import DashboardLeft from '../components/dashboard/DashboardLeft'
import DashboardRight from '../components/dashboard/DashboardRight'
import DashboardRightFamily from '../components/dashboard/DashboardRightFamily'
import DashboardHeader from '../components/layout/DashboardHeader'
import axioInstance from '../components/axiosInstance'
import { inWords } from '../components/global'
import arrowImg from '../img/svg/arrow.svg';
import { Encryption } from '../components/global'

import FinnLoader from '../components/FinnLoader'

export default function Dashboard() {

    const [target, setTarget] = useState();
    const [assets, setAssets] = useState();
    const [retirement, setRetirement] = useState([]);
    const [incomeGrow, setIncomeGrow] = useState();

    // eslint-disable-next-line
    const [savingTarget, setSavingTarget] = useState("");
    const [ongoingSaving, setOngoingSaving] = useState("");

    const [familyCount, setFamilyCount] = useState();
    const [assumptions, setAssumptions] = useState([]);
    const [riskDescription, setRiskDescription] = useState();
    const [totalTarget, setTotalTarget] = useState(0);

    const [totalAssets, setTotalAssets] = useState(0);

    // eslint-disable-next-line
    const [name, setName] = useState(sessionStorage.getItem('full_name'));

    const [stepper, setStepper] = useState(0);
    const [roi, setRoi] = useState();
    const [tillDate, setTillDate] = useState();

    const [roiOption, setRoiOption] = useState();
    const [riskAssetMax, setRiskAssetMax] = useState();

    const [cashflowOption, setCashflowOpt] = useState();

    // eslint-disable-next-line
    const [rightTab, setRightTab] = useState('plan_r');
    // eslint-disable-next-line
    const [verticalFamilyTab, setVerticalFamilyTab] = useState('members');

    const [planCompleteData, setPlanCompleteData] = useState([]);
    const [planCashflowData, setPlanCashflowData] = useState([]);

    const [componentShow, setComponentShow] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const profile_id = sessionStorage.getItem('profile_id');

        setLoading(true);

        axioInstance.post(`profile/summary`, { profile_id: profile_id }).then(
            (response, data) => {

                setPlanCompleteData(response.data);
                setComponentShow(true);

                setName(
                    response.data.self_data &&
                        (response.data.self_data.first_name ||
                            response.data.self_data.middle_name ||
                            response.data.self_data.last_name)
                        ? [response.data.self_data.first_name,
                        response.data.self_data.middle_name,
                        response.data.self_data.last_name]
                            .filter(name => name) // Remove null/empty values
                            .join(" ") // Combine names with spaces
                            .toLowerCase()
                            .split(" ")
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
                            .join(" ")
                        : "" // Fallback to empty string if no valid name fields exist
                );

                if (response.data.self_data.stepper < 3) {
                    window.location.href = 'basic-profile';
                    return
                }
                setTarget(response.data.target_amount);
                setTotalTarget(response.data.total_target);

                if (response.data.self_data.stepper >= 6) {
                    setAssets(response.data.plan_assets);
                }

                setTotalAssets(response.data.total_plan_assets)

                let find_retirement = response.data.goals.find(product => product.goal_type === "Retirement" && product.relation === "self");
                setRetirement(find_retirement);

                setIncomeGrow(parseInt(response.data.assumption.income_grow));
                setStepper(response.data.self_data.stepper);

                console.log('response.data.profgoalSummary.suggested_fund', response.data.profgoalSummary.suggested_fund, 'response.data.InvestmentSummary.c_amount', response.data.InvestmentSummary.c_amount, 'response.data.InvestmentSummary.cash_amount', response.data.InvestmentSummary.cash_amount, 'response.data.profgoalSummary.shortfall_lumpsum', response.data.profgoalSummary.shortfall_lumpsum, 'response.data.profgoalSummary.shortfall_yearly_with_increase', response.data.profgoalSummary.shortfall_yearly_with_increase);

                if (response.data.profgoalSummary.cashflow_opt === "1") {
                    setSavingTarget((response.data.profgoalSummary.suggested_fund - (response.data.InvestmentSummary.c_amount - response.data.InvestmentSummary.cash_amount) * response.data.profgoalSummary.shortfall_lumpsum) / response.data.profgoalSummary.shortfall_yearly_with_increase);
                } else if (response.data.profgoalSummary.cashflow_opt === "2") {
                    setSavingTarget(parseInt(response.data.incomeSum.incomeSum) - parseInt(response.data.outflow));
                } else if (response.data.profgoalSummary.cashflow_opt === "3") {
                    setSavingTarget(response.data.profgoalSummary.custom_amount);
                }

                setFamilyCount(response.data.familyCount);
                setAssumptions(response.data.assumption);
                setOngoingSaving(response.data.InvestmentSummary.y_amount);
                setRiskDescription(response.data.risk_profile.description ? response.data.risk_profile.description : 'Set');

                if (response.data.profgoalSummary.roiOption === '1') {
                    axioInstance.post(`summary/calculate-roi`, { profile_id: profile_id, asset_alloc: response.data.risk_profile.max_val }).then(
                        (response, data) => {
                            setRoi((response.data.roi * 100).toFixed(2));
                        });
                } else {
                    setRoi(parseFloat(response.data.profgoalSummary.pre_return * 100).toFixed(2));
                }


                setTillDate(response.data.self_data.till_date);

                setRoiOption(response.data.profgoalSummary.roiOption);

                setRiskAssetMax(response.data.risk_profile.max_val);

                setCashflowOpt(response.data.profgoalSummary.cashflow_opt === "1" ? "Recommended" : response.data.profgoalSummary.cashflow_opt === "2" ? "Ongoing + surplus" : response.data.profgoalSummary.cashflow_opt === "3" ? "Desired Target" : "Recommended");

                let spouse_prof_det_id = response.data.prof_det.find(product => product.relation === "spouse");

                if (spouse_prof_det_id) {
                    sessionStorage.setItem('spouse_prof_det_id', Encryption(spouse_prof_det_id.profile_details_id));
                }



                setLoading(false);
            });

        axioInstance.post(`cashflow-summary-graph`, { profile_id: profile_id }).then(
            (response, data) => {
                setPlanCashflowData(response.data);
            });


    }, []);

    return (
        <Fragment>
            {loading && <FinnLoader />}

            <DashboardHeader />
            <div className="innercontainer mb40">
                <div className="mainwrapper">

                    <div className='form_title'>

                        <div className="mt30 font21 fw600 color384">{name} Financial Fitness Plan</div>
                        <div className="font16 color626">Calculation of required amount to achieve desired financial goals.</div>

                        {
                            stepper < 6 ? <div className='mt30'><a className='btn bluebtn wizard-btn backtowizardBtn font14 fw600' href='/basic-profile'>Back to wizard</a> </div> : ''
                        }

                    </div>


                    <div className="infoBox_wpr mt25">
                        <div className="infoBox_outer act">
                            <div className="infoBox_title">Total need to achieve all the goals</div>
                            <div className="mt15 infoBox_number goldtext">
                                {target}
                            </div>
                            <div className="infoBox_footertext mt20">{retirement.target_amt} Retirement corpus</div>
                        </div>

                        <div className="infoBox_outer">
                            <div className="infoBox_title">Your total investments for goals so far</div>
                            <div className="mt15 infoBox_number greentext">

                                {
                                    assets ? inWords(assets) : '----'
                                }
                                <progress value={(parseInt(totalAssets) / parseInt(totalTarget)) * 100} max="100"></progress>
                            </div>
                            {
                                assets ? <div className="infoBox_footertext mt20">{inWords(parseInt(totalTarget) - parseInt(totalAssets))} remaining</div> : ''
                            }

                        </div>

                        <div className="infoBox_outer">
                            <div className="infoBox_title">
                                Next year’s {cashflowOption === "Recommended" ? "Recommended" : "Target"}
                                <div>Today <img src={arrowImg} alt='Date' /> {tillDate}</div>
                            </div>
                            <div className="mt15 infoBox_number bluetext">
                                {
                                    savingTarget > 0 ? inWords(savingTarget) : '----'
                                }
                            </div>
                            <div className="infoBox_footertext mt20">{incomeGrow > 0 ? 'with ' + incomeGrow + '% increase yearly' : 'No yearly increase'}</div>
                        </div>

                        <div className="infoBox_outer">
                            <div className="infoBox_title">Yearly systemetic ongoing investment (this year)</div>
                            <div className="mt15 infoBox_number goldtext">
                                {
                                    ongoingSaving > 0 ? inWords(ongoingSaving) : '----'
                                }
                                <progress value={Math.round((ongoingSaving / savingTarget) * 100)} max="100"></progress>
                            </div>
                            <div className="infoBox_footertext mt20">{Math.round((ongoingSaving / savingTarget) * 100)}% of next year’s need</div>
                        </div>

                        <div className="infoBox_outer">
                            <div className="infoBox_title">Your remaining need to achieve next year’s target</div>
                            <div className="mt15 infoBox_number redtext">
                                {(savingTarget - ongoingSaving) > 0 ? inWords((savingTarget - ongoingSaving)) : '0'}
                            </div>
                            <div className="infoBox_footertext mt20 bluetext">Get investment advise</div>
                        </div>
                    </div>


                    <div className="dashboard_outer mt30">
                        {/*<DashboardLeft familyCount={familyCount} assumptions={assumptions} riskDescription={riskDescription} name={name} roi={roi} roiOption={roiOption} riskAssetMax={riskAssetMax} rightTab={rightTab} setRightTab={setRightTab} verticalFamilyTab={verticalFamilyTab} setVerticalFamilyTab={setVerticalFamilyTab} planCompleteData={planCompleteData} /> */}

                        {componentShow && (
                            <DashboardLeft familyCount={familyCount} assumptions={assumptions} riskDescription={riskDescription} name={name} roi={roi} roiOption={roiOption} riskAssetMax={riskAssetMax} rightTab={rightTab} setRightTab={setRightTab} verticalFamilyTab={verticalFamilyTab} setVerticalFamilyTab={setVerticalFamilyTab} planCompleteData={planCompleteData} />
                        )}
                        {/*rightTab === 'family_r' ? <DashboardRightFamily verticalFamilyTab={verticalFamilyTab} planCompleteData={planCompleteData}/> : <DashboardRight planCompleteData={planCompleteData}/>*/}

                        {componentShow && (
                            rightTab === 'family_r' ? (
                                <DashboardRightFamily verticalFamilyTab={verticalFamilyTab} planCompleteData={planCompleteData} />
                            ) : (
                                <DashboardRight planCompleteData={planCompleteData} planCashflowData={planCashflowData}/>
                            )
                        )}
                    </div>

                </div>
            </div>




        </Fragment>
    )
}
