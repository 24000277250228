import React, { Fragment, useState, useEffect } from 'react';
//import ReactDOM from 'react-dom';
import axioInstance from '../components/axiosInstance';
// eslint-disable-next-line
import { stepMaster, registerVia, officeBranchList } from '../components/global'
import { Link, useNavigate } from 'react-router-dom'
//import DataTable from 'react-data-table-component';
import Register from './Register';
import * as copy from 'copy-to-clipboard';
//import { Dropdown } from 'react-bootstrap'
import SweetAlert from 'sweetalert2'
import PlanStatus from './PlanStatus';
import AccountManagerMap from './AccountManagerMap';
//import AdminDashboardHeader from '../components/layout/AdminDashboardHeader';
import { Modal, ModalBody } from 'react-bootstrap';
import AddTestProfileSidebar from '../components/AddTestProfileSidebar';
import ProfileInterestStatus from '../components/ProfileInterestStatus';
import AddDirectProfileSidebar from '../components/AddDirectProfileSidebar';
import { Encryption  } from '../components/global';
//import FinnLoader from '../components/FinnLoader';
import NewTheme from '../components/admin-dashboard/NewTheme';
//import AdminDashboardFilterForm from '../components/admin-dashboard/AdminDashboardFilterForm';
import CardBox from '../components/admin-dashboard/CardBox';
import TableContainer from '../components/admin-dashboard/TableContainer'; 
import { AvatarImage } from '../components/global';
import SidebarPopupComponent from '../components/admin-dashboard/SidebarPopupComponent';
 
//const DropdownWithPortal = ({ children }) => ReactDOM.createPortal(children, document.body);

export default function AdminDashboard() {

  let navigate = useNavigate();

  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  // eslint-disable-next-line
  const [tableRowsData, setTableRowsData] = useState([]);

  const [ptype, setPtype] = useState("");
  const [riskptype, setRisktype] = useState("");

  const [adminName, setAdminName] = useState("");
  const [relManager_, setRelManager_] = useState("");

  const [serManager_, setSerManager_] = useState("");
  const [boUser_, setBoUser_] = useState("");
  const [branch_, setBranch_] = useState("");

  const [daterange_column_name , setDaterange_column_name] = useState("");
  const [from_date, setFrom_date] = useState("");
  const [to_date, setTo_date] = useState("");

  // eslint-disable-next-line
  const [rmorcby, setRmorcby] = useState("");
  const [planStage, setPlanStage] = useState("0");

  const [loading, setLoading] = useState(true);

  const [prxfixFilters, setPrefixFilters] = useState("");
  const [cardSubFilter, setCardSubFilter] = useState("");

    // eslint-disable-next-line 
    const [reportToList, setReportToList] = useState([]);

  
  // Read ptype from URL and set it as initial state
  useEffect(() => { 
    const params = new URLSearchParams(window.location.search);

    const allParams = new URLSearchParams(window.location.search);
    allParams.delete("card_sub_filter");
    console.log("Line no. 202 toString:", allParams.toString());
    setPrefixFilters(allParams.toString() )

    const ptypeFromUrl = params.get('ptype');

    const risktypeFromUrl = params.get('risktype');

    const planStageUrl = params.get('planstage');

    const rmorcbyFromUrl = params.get('rmorcby');

    const admin_nameFromUrl = params.get('admin_name');
    const rel_managerFromUrl = params.get('rel_manager');

    const ser_managerFromUrl = params.get('ser_manager');
    const bo_userFromUrl = params.get('bo_user');
    const branchFromUrl = params.get('branch');

    const daterange_column_nameFromUrl = params.get('daterange_column_name');
    const from_dateFromUrl = params.get('from_date');
    const to_dateFromUrl = params.get('to_date');

    if (ptypeFromUrl !== null) {
      setPtype(ptypeFromUrl);
    }

    if (risktypeFromUrl !== null) {
      setRisktype(risktypeFromUrl);
    }

    if (planStageUrl !== null) {
      setPlanStage(planStageUrl);
    }

    if (rmorcbyFromUrl !== null) {
      setRmorcby(rmorcbyFromUrl);
    }

    if(admin_nameFromUrl !== null){
      setAdminName(admin_nameFromUrl);
    }

    if(rel_managerFromUrl !== null){
      setRelManager_(rel_managerFromUrl);
    }

    if(ser_managerFromUrl !== null){
      setSerManager_(ser_managerFromUrl);
    }

    if(bo_userFromUrl !== null){
      setBoUser_(bo_userFromUrl);
    }
    if(branchFromUrl !== null){
      setBranch_(branchFromUrl);
    }
    
    if(daterange_column_nameFromUrl !== null){
      setDaterange_column_name(daterange_column_nameFromUrl);
    }

    if(from_dateFromUrl !== null){
      setFrom_date(from_dateFromUrl);
    }

    if(to_dateFromUrl !== null){
      setTo_date(to_dateFromUrl);
    }




  }, []);


  useEffect(() => {
   console.log("reportToList::::", reportToList);
  }, [reportToList]);

  const getRiskProfileView = (id, enc_profile) => {


    const link = 'https://plan.finnovate.in/risk-profile-view?id=' + Encryption(id) + '&pid=' + enc_profile;

    if (copy(link)) {

      SweetAlert.fire({
        toast: true,
        icon: 'success',
        title: 'Link Copied Successfully',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', SweetAlert.stopTimer)
          toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
        }
      })
    } else {
      SweetAlert.fire(
        'Copy Link',
        'Something went wrong',
        'error'
      );
    }

  }


  const loginUser = (id, emailAddress) => {
    axioInstance.get(`admin/user-login/` + id).then(
      (response, data) => {
        sessionStorage.setItem('token', response.data.token);
        const pdata = { "email": Encryption(emailAddress) };
        axioInstance.post(`profile/view`, pdata).then(function (response) {


          sessionStorage.setItem('profile_id', response.data.enc_profile);
          //sessionStorage.setItem('full_name', response.data.first_name + '\'s');

          sessionStorage.setItem(
            'full_name',
            [response.data.first_name, response.data.middle_name, response.data.last_name] // Include all name fields
              .filter(name => name) // Remove null or empty values
              .join(" ") // Combine valid names with spaces
              .toLowerCase() // Convert the entire string to lowercase
              .split(" ") // Split into words
              .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
              .join(" ") // Join words back with spaces
          );


          sessionStorage.setItem('profile_details_id', response.data.enc_profile_details_id);

          axioInstance.post(`login-logout-logs`, { user_type: 'adminclient', action: 'login', profile_id: response.data.enc_profile, admin_id: sessionStorage.getItem("admin_id") });

          if (response.data.step > 3) {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/basic-profile";
          }
        });
      }
    );
  }

  const [boxTotals, setBoxTotals] = useState([]);



  useEffect(() => {

    console.log('window.location', window.location.hostname);

    const loggedInUser = localStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {
      /**
       * ?admin_name=&rel_manager=&ptype=&risktype=2&planstage=3&daterange_column_name=created_date&from_date=&to_date=
       */
      const params = new URLSearchParams(window.location.search);
      // const paramsObject = Object.fromEntries(params.entries());
      // console.log("Line no. 201 params:", paramsObject);
    

      const ptype = params.get('ptype') ? params.get('ptype') : '';
      const risktype = params.get('risktype') ? params.get('risktype') : '';
      const planstage = params.get('planstage') ? params.get('planstage') : '';
      const rmorcby = params.get('rmorcby') ? params.get('rmorcby') : '';

      const selected_rel_manager = params.get('rel_manager') ? params.get('rel_manager') : '';

      const selected_ser_manager = params.get('ser_manager') ? params.get('ser_manager') : '';
      const selected_bo_user = params.get('bo_user') ? params.get('bo_user') : '';
      const selected_branch = params.get('branch') ? params.get('branch') : '';

      const admin_name = params.get('admin_name') ? params.get('admin_name') : '';
      const daterange_column_name_ = params.get('daterange_column_name') ? params.get('daterange_column_name') : '';
      const from_date_ = params.get('from_date') ? params.get('from_date') : '';
      const to_date_ = params.get('to_date') ? params.get('to_date') : '';
      const card_sub_filter = params.get('card_sub_filter') ? params.get('card_sub_filter') : '';
      
      setCardSubFilter(card_sub_filter);
      const PostData = {
        rel_manager: localStorage.getItem('admin_id'),
        rmorcby: rmorcby,
        admin_role: sessionStorage.getItem('admin_role'),
        testProfile: ptype,
        risktype: risktype,
        planstage: planstage,
        selected_rel_manager:selected_rel_manager,
        selected_ser_manager:selected_ser_manager,
        selected_bo_user:selected_bo_user,
        selected_branch:selected_branch,
        admin_name:admin_name,
        daterange_column_name:daterange_column_name_,
        from_date:from_date_,
        to_date:to_date_,
        encrypted_key: Encryption("anojtambe"),
        card_sub_filter: card_sub_filter
      }
      console.log('PostData', PostData);
      /*const filteredPostData = Object.fromEntries(
        Object.entries(PostData).filter(([_, value]) => value !== '')
      );*/

      axioInstance.post(`admin/get-all-profiles`, PostData).then(
        (response, data) => {
          //console.log(response.data);
          setData(response.data.data);
          setTableRowsData(response.data.data);
          setBoxTotals(response.data.count_data_res.length > 0 ? response.data.count_data_res[0] : []);
          setLoading(false);
        }
      );

      axioInstance.get(`admin/get-admins`).then(
        (response, data) => {
          //console.log(response.data);
          setRelManager(response.data);
        }
      );

      axioInstance.post(`admin/get-admins-reportto`, {
        admin_id: localStorage.getItem('admin_id'),
        admin_role: sessionStorage.getItem('admin_role')
     }).then((response, data) => { 
        setReportToList(response.data); 
      });


      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axioInstance.post(`admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);

  /*   const customStyles = {
      rows: {
        style: {
          minHeight: '65px', // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: '8px', // override the cell padding for the head cells
          paddingRight: '8px',
        },
      },
      cells: {
        style: {
          paddingLeft: '8px', // override the cell padding for data cells
          paddingRight: '8px',
        },
      },
    }; */

  const [profileId, setProfileId] = useState();

  const RiskLink = (id, enc_profile) => {
    const link = 'https://plan.finnovate.in/risk-profile-set?profile_id=' + enc_profile;

    if (copy(link)) {

      const config = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
      };

      const postData = { link: link, module_name: 'risk_profile', profile_id: Encryption(id), created_by: sessionStorage.getItem('admin_id') };
      axioInstance.post(`url-shared`, postData, config).then(
        (response, data) => {

        }
      );

      SweetAlert.fire({
        toast: true,
        icon: 'success',
        title: 'Link Copied Successfully',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', SweetAlert.stopTimer)
          toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
        }
      })
    } else {
      SweetAlert.fire(
        'Copy Link',
        'Something went wrong',
        'error'
      );
    }


  }

  const UpdateRelManager = () => {
    var postData = {
      'profile_id': profile_id,
      'rel_manager': admin_id
    }
    axioInstance.post(`profile/update-relmanager`, postData)
      .then(function (response) {

        SweetAlert.fire(
          'Profile',
          'Successfully Updated Rel Manager.',
          'success'
        ).then(function () {
          window.location.href = "/admin-dashboard"
        })

      })
      .catch(function (error) {
        SweetAlert.fire(
          'Profile',
          'Failed.',
          'error'
        )
      });
  }


  const getViewLink = (ref_url) => {



    if (ref_url) {

      SweetAlert.fire({
        title: "REF URL",
        text: ref_url ? ref_url : 'NA',
        icon: "success"
      });

    } else {
      SweetAlert.fire({
        title: "REF URL",
        text: "NA",
        icon: "error"
      });
    }




  }

  const get_profile_pan = (profile_id) => {

    axioInstance.post(`profile/pan`, { 'profile_id': Encryption(profile_id) })
      .then(function (response) {

        if (response.data.pan) {

          SweetAlert.fire({
            title: response.data.pan,
            text: "PAN",
            icon: "success"
          });

        } else {
          SweetAlert.fire({
            title: "Error",
            text: "Something went wrong or No PAN Found",
            icon: "error"
          });
        }

      })
      .catch(function (error) {
        SweetAlert.fire(
          'Error',
          'Something went wrong or No PAN Found.',
          'error'
        )
      });


  }

  const setDefaultPassword = (email) => {

    SweetAlert.fire({
      title: "Set Default Password",
      text: "This will change the current password. Continue ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {


          axioInstance.post(`profile/update-password`, { 'email': email, 'confirm_password': 'Finno@1234' }).then(
            function (response) {

              SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Successfully Set Default Password - Finno@1234',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', SweetAlert
                    .stopTimer)
                  toast.addEventListener('mouseleave', SweetAlert
                    .resumeTimer)
                }
              }).then(function () {

                window.location.reload();

              });
            }
          )

        }
      });

  }

  const markTestProfile = (profile_id) => {

    SweetAlert.fire({
      title: "Mark as Test Profile",
      text: "Are you sure want to mark as Test profile?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axioInstance.post(`profile/mark-as-testprofile`, { 'profile_id': profile_id }).then(
          function (response) {

            SweetAlert.fire({
              toast: true,
              icon: 'success',
              title: 'Successfully Marked Profile as Testing',
              animation: false,
              position: 'top-right',
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', SweetAlert
                  .stopTimer)
                toast.addEventListener('mouseleave', SweetAlert
                  .resumeTimer)
              }
            }).then(function () {

              window.location.reload();

            });
          }
        )
      }
    });

  }



  /*const EditRelManager = (id) => {
    setProfile_id(id);
    toggleShow();
  }*/


  // eslint-disable-next-line
  const [title, setTitle] = useState("");
  
 
  const [relManager, setRelManager] = useState([])
  const [profile_id, setProfile_id] = useState();
  const [modal, setModal] = useState(false);
  //const toggleShow = () => setModal(true);
  const toggleClose = () => setModal(false);

  const [admin_id, setadmin_id] = useState();


  const handleChange = (e) => {
    const { value } = e.target;
    setadmin_id(value);
  }


  /*   const addProfile = () => {
  
      console.log('hostname', window.location);
  
      const url = window.location.origin + "/sign-in?q=2&ref_by=" + sessionStorage.getItem('shortName') + "&s=" + sessionStorage.getItem('admin_id');
  
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
  
    } */

  const [show, setShow] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);


  const [planStatus, setPlanStatusShow] = useState(false);
  const planStatusClose = () => setPlanStatusShow(false);
  const planStatusShow = () => setPlanStatusShow(true);

  const EditPlanStatus = (id) => {
    setProfile_id(id);
    planStatusShow();
  }

  const [accountManager, setAccountManager] = useState(false);
  const accountManagerClose = () => setAccountManager(false);
  const accountManagerShow = () => setAccountManager(true);

  const UpdateAccountManager = (id) => {
    setProfile_id(id);
    accountManagerShow();
  }

  const [waitlisProfile, setWaitlistProfile] = useState();
  const waitlistPopup = (id) => {
    setShowWaitlist(true);
    setWaitlistProfile(id);
  }

  const updateWaitlist = (id) => {

    axioInstance.post(`profile/update-profile-waitlist`, { 'profile_id': Encryption(waitlisProfile) }).then(
      function (response) {

        SweetAlert.fire({
          toast: true,
          icon: 'success',
          title: 'Successfully Updated Profile',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
          }
        }).then(function () {

          window.location.href = "/admin-dashboard";

        });

      }
    )

  }

  const [isActive, setActive] = useState(false);

  const [action, setAction] = useState();
 
  const deleteUser = (id, profile_id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "you want to delete this user?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: false
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axioInstance.post(`admin/user-delete`, { 'id': id, 'profile_id': Encryption(profile_id) }).then(
            function (response) {
              SweetAlert.fire(
                'User',
                'Successfully Deleted.',
                'success'
              ).then(function () {
                window.location.href = "/admin-dashboard";
              });
            }
          )
        }
      });
  }
   
// eslint-disable-next-line
  const[rowAppendDone, setRowAppendDone] = useState(false);
  const [trigger, setSidebarTrigger] = useState(false); 
// eslint-disable-next-line
  const [currentSearch, setCurrentSearch]  = useState('/'); 
 // const [uniqueRelManager, setUniqueRelManager] = useState([]) 
  // eslint-disable-next-line
  const [url_change, set_url_change] = useState(new URL(window.location.href));
  useEffect(() => {
   // setUniqueRelManager([...new Set(tableRowsData.map(item => item.primary_rel_manager))].filter(manager => manager).sort((a, b) => a.localeCompare(b)));
    //console.log("uniqueRelManager", uniqueRelManager);
    setTimeout(()=>{
     
        // Ensure the DOM is ready and `applyDataTable` is available globally
        if (typeof window.applyDataTable === "function") {

          window.applyDataTable(
            '#datatable_1',
            {
              "bFilter": true,
              "order": [[1, 'desc']]
            },
            function (datatable) {

              // Add custom filtering logic
             /* 
              const adminFilterInput = document.getElementById('admin_filter_name');
              if (adminFilterInput) {
                adminFilterInput.addEventListener('keyup', function () {
                  datatable.column(2).search(this.value).draw();
                });
              }

              const filter_relManager = document.getElementById('filter_relManager');
              if (filter_relManager) {
                filter_relManager.addEventListener('change', function () {
                  datatable.column(5).search(this.value).draw();
                });
              } */

            }

          );
          console.log("line no 873");
        } 
      },2500); 
  }, [tableRowsData]); // Empty dependency array ensures this runs only once on mount


  useEffect(()=>{
    const url = new URL(window.location.href);
    setCurrentSearch(url.search);
    // console.log("url: ", url.search);
  }, [])

  const handleOpenFilterClick = () => {
    console.log("open filter true");
    setSidebarTrigger(true)
  }

  const resetFilter = () => { 
    window.location.href = 'admin-dashboard'; 
  }
  
  
  return (
    <NewTheme loading={loading}>

      <div className="page-header">
        <div className="content-page-header">
          <h5>Admin Dashboard</h5>
          <div className="list-btn">
            <ul className="filter-list">
              <li>
                <button onClick={handleOpenFilterClick} type='button' className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                  <span className="me-2"><img src="assets/img/icons/filter-icon.svg" alt="filter" /></span>Filter
                </button>
              </li>
              {/*   <li>
                          <a className="btn btn-primary" href="add-invoice.html"><i className="fa fa-plus-circle me-2" aria-hidden="true"></i>New Invoice</a>
                      </li> */}
            </ul>
          </div>
        </div>
      </div>


      <Modal show={showWaitlist} onHide={handleClose} className='modal model-sm' dialogClassName='modal-container' keyboard centered>
        <ModalBody>
          <div className="p20">
            <div className="text-center">
              <span className={"addIcon"} />
            </div>
            <div className="text-center mt10 color1e3 fw600 font18">
              {"Waitlisted"}
            </div>
            <div className="text-center mt10 color485 fw500 font14">
              {"Are you sure want to remove from waitlist and active profile"}
            </div>
            <div className="smallbtn mt20">
              <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>Cancel</button>
              <button type="button" className={"btn btn-danger"} onClick={updateWaitlist}>Yes</button>
            </div>
          </div>
        </ModalBody>
      </Modal>


      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Register ulogin={1} relManager={sessionStorage.getItem('admin_id')} />
        </Modal.Body>
      </Modal>

      <Modal show={planStatus} onHide={planStatusClose}>
        <Modal.Body>
          <PlanStatus planStatusClose={planStatusClose} profile_id={profile_id} />
        </Modal.Body>
      </Modal>

      <Modal show={accountManager} onHide={accountManagerClose}>
        <Modal.Body>
          <AccountManagerMap accountManagerClose={accountManagerClose} profile_id={profile_id} />
        </Modal.Body>
      </Modal>

      <Modal show={modal} onHide={toggleClose}>
        <Modal.Header>{"Assign/Update Rel Manager"}</Modal.Header>
        <Modal.Body>
          <div className='col-md-12'>
            <br />
            <select className="form-control digits" name={"admin_id"} value={admin_id} onChange={handleChange} autoComplete="off">
              <option value="">select</option>
              {relManager.map((data, i) => {
                return (
                  <option value={data.admin_id} key={i}>{data.admin_name}</option>
                )
              })}
            </select>
            <br />
            <button className="btn btn-primary" type='button' onClick={() => UpdateRelManager()}> Update Rel Manager </button>
          </div>
        </Modal.Body>
      </Modal>


    {/*   <AdminDashboardFilterForm
        relmanagerFilter={relmanagerFilter}
        relManager={relManager}
        profileFilter={profileFilter}
        ptype={ptype}
        riskprofileFilter={riskprofileFilter}
        riskptype={riskptype}
        planStageFilter={planStageFilter}
        planStage={planStage}
        trigger={trigger}
        setSidebarTrigger={setSidebarTrigger}
        uniqueRelManager={uniqueRelManager}
      /> */}



      <div className="row"> 

        <CardBox icon="assets/img/icons/receipt-item.svg" title="Total Plan" value={boxTotals?.total_count || 0} 
        //link_url="/admin-dashboard" 
        link_url={'/admin-dashboard?' + prxfixFilters + '&card_sub_filter=total_plan'}
        active={cardSubFilter === 'total_plan' ? true : false} />     

        <CardBox icon="assets/img/icons/transaction-minus.svg" title="Total Shared" value={boxTotals?.total_plan_shared || 0} 
        //  link_url="/admin-dashboard?planstage=5" 
        link_url={'/admin-dashboard?' + prxfixFilters + '&card_sub_filter=total_shared'} 
        active={cardSubFilter === 'total_shared' ? true : false}  counter_title="Pending Risk Profiles" counter_value={boxTotals.total_risk_profile_pending} counter_value_color="danger" /> 

        <CardBox icon="assets/img/icons/archive-book.svg" title="Total Freezed" value={boxTotals?.total_plan_freezed || 0} 
        //link_url="/admin-dashboard?planstage=7"
        link_url={'/admin-dashboard?' + prxfixFilters + '&card_sub_filter=total_freezed'} 
         active={cardSubFilter === 'total_freezed' ? true : false}  />

        <CardBox icon="assets/img/icons/clipboard-close.svg" title="Total Pending" value={boxTotals?.total_retirement_goal_created || 0} 
        //link_url="/admin-dashboard?planstage=1" 
        link_url={'/admin-dashboard?' + prxfixFilters + '&card_sub_filter=total_pending'}
        active={cardSubFilter === 'total_pending' ? true : false}  />

        <CardBox icon="assets/img/icons/message-edit.svg" title="Total Waitlisted" value={boxTotals?.total_waitlisted_count || 0} 
        // link_url="/admin-dashboard?ptype=2" 
        link_url={'/admin-dashboard?' + prxfixFilters + '&card_sub_filter=total_waitlisted'}
        active={cardSubFilter === 'total_waitlisted' ? true : false}  />

        <CardBox icon="assets/img/icons/3d-rotate.svg" title="Total Testing" value={boxTotals?.total_testing_profile_count || 0} 
        // link_url="/admin-dashboard?ptype=1"
        link_url={'/admin-dashboard?' + prxfixFilters + '&card_sub_filter=total_testing'}
         active={cardSubFilter === 'total_testing' ? true : false}  />

      </div>
      
      
   
      

      <TableContainer>
        <table className="table table-stripped table-hover " id="datatable_1">
          <thead className="thead-light">
            <tr>
              <th>Action</th>
              <th>ID</th>
              <th>Name | Email</th>
              <th>Phone</th>
              <th>UTM Source | Reg. Via</th>
              <th>Rel Manager</th>
              <th>Planner</th>
              <th>Created By | Created On</th>
              <th>Stage On | Plan Status | Status </th> 
            </tr>
          </thead>
          <tbody>
            {tableRowsData && tableRowsData.length === 0 &&  <tr><td colspan="9">No record found !</td></tr> }
            {
            tableRowsData && tableRowsData.map((row, index) => { 
                let color = row.email_verified === "verified" ? 'green' : 'red';
                return <tr key={index}>
                  <td className="text-end">
                    <div className="dropdown dropdown-action" style={{ float: 'left' }}>
                      <Link className="btn-action-icon" data-bs-toggle="dropdown"
                        aria-expanded="false"><i className="fas fa-ellipsis-v"></i></Link>
                      <div className="dropdown-menu dropdown-menu-end">

                        {sessionStorage.getItem("admin_role") === "0" && <Link className="dropdown-item" onClick={() => deleteUser(row.id, row.profile_id)} >
                          <i className="far fa-trash-alt me-2"></i>Delete user</Link>}


                        <Link className="dropdown-item" onClick={() => RiskLink(row.id, row.enc_profile)}>
                          <i className="far fa-paste me-2"></i>Risk Profile Link</Link>

                        {(row.waitlist === 1 && sessionStorage.getItem("admin_role") === "0") &&
                          <> <Link className="dropdown-item" onClick={() => waitlistPopup(row.profile_id)}>
                            <i className="fa fa-user me-2"></i>Mark Active</Link> </>
                        }


                        <Link className="dropdown-item" onClick={() => EditPlanStatus(row.profile_id)}>
                          <i className="fa fa-align-center me-2"></i>Update Plan Status</Link>

                        <Link className="dropdown-item" onClick={() => UpdateAccountManager(row.profile_id)}>
                          <i className="fa fa-align-center me-2"></i> Update RM & Planner</Link>



                        {
                          sessionStorage.getItem("admin_role") === "0" &&
                          <Link className="dropdown-item" onClick={() => markTestProfile(row.profile_id)}>
                            <i className="fa fa-user-alt-slash me-2"></i>Mark As Test <br /> Profile</Link>
                        }

                        {
                          sessionStorage.getItem("admin_role") === "0" &&
                          <Link className="dropdown-item" onClick={() => setDefaultPassword(row.email)}>
                            <i className="fa fa-unlock me-2"></i>Set Password</Link>
                        }

                        <Link className="dropdown-item" onClick={() => get_profile_pan(row.profile_id)}>
                          <i className="far fa-id-card me-2"></i>Get PAN</Link>

                        <Link className="dropdown-item" onClick={() => { setActive(true); setAction('profileInterest'); setProfileId(row.profile_id) }}>
                          <i className="fa fa-tasks me-2"></i>Profile Interest</Link>

                        <Link className="dropdown-item" onClick={() => getViewLink(row.ref_link)}>
                          <i className="far fa-id-card me-2"></i>View Url</Link>

                        <Link className="dropdown-item" onClick={() => getRiskProfileView(row.id, row.email)}>
                          <i className="far fa-id-card me-2"></i>Risk Profile View</Link>
                      </div>
                    </div>
                    <span className='cursor_pointer' onClick={() => loginUser(row.id, row.email)}><i className="fas fa-sync-alt" style={{ width: 35, fontSize: 16, color: '#06B153', cursor: 'pinter' }}></i></span>
                  </td>
                  <td>{row.profile_id}</td>
                  <td>
                    <h2 className="table-avatar">
                      <Link to="#" className="avatar avatar-sm me-2"> 
                        <img className="avatar-img rounded-circle" src={AvatarImage(row.gender)} alt="" />
                        </Link>
                      <Link to="#">
                        {row.name} <span>
                          <span className={" __cf_email__ " + color} >{row.email}</span> 
                        </span></Link>
                    </h2>
                  </td>
                  <td className={color}>{row.phone}</td>
                  <td>{row.utm_source ? row.utm_source : 'NA'} <br /> {registerVia(row.registerVia)}</td>
                  <td>{row.primary_rel_manager}</td>
                  <td>{row.plan_ser_manager}</td>
                  <td>{row.admin_name}<br/> {row.create_on}</td>
                  <td><span title="step">{stepMaster(row.step)}</span> <br/> <span className="badge bg-success-light" title="planStatusCurrent"> {row.planStatusCurrent}</span><br/> <Status testProfile={row.testProfile} waitlist={row.waitlist} /></td>
                  
                </tr>
                
              })
            }
          </tbody>
        </table>
      </TableContainer>
      <div className="col-dm-12">
        <strong className='font12 mt-3'>Indicators : Verified Email/Mobile - <span className='text-success'>green</span>, Unverified Email/Mobile- <span className='text-danger'>Red</span></strong>
      </div>


      <SidebarPopupComponent id="sidebar_1"  title='Filter Admin Users Data' trigger={trigger} setSidebarTrigger={setSidebarTrigger}>  
        <form method='GET' id="serach_from">
          <div className="form-group">
            <label>Name </label>
            <input type="text" className=" filter_name form-control" name='admin_name'  id="admin_filter_name" placeholder="Search Name" defaultValue={adminName} />
          </div>

          <div className='form-group'>
          <label>Rel Manager </label>
            <select className="custom-select form-control" name='rel_manager' id="filter_relManager"  defaultValue={relManager_} > 
              <option value="">ALL</option>
              <option value="No Primary">No Primary</option> 
                {reportToList.map((data, i) => {
                return (
                  <option value={data.short_name} key={i}>{data.short_name}</option>
                )
              })}

            </select> 
          </div>

          <div className='form-group'>
          <label>Ser Manager </label>
            <select className="custom-select form-control" name='ser_manager' id="filter_serManager"  defaultValue={serManager_} > 
              <option value="">ALL</option>
              <option value="No Primary">No Primary</option> 
                {reportToList.map((data, i) => {
                return (
                  <option value={data.short_name} key={i}>{data.short_name}</option>
                )
              })}

            </select> 
          </div>

          <div className='form-group'>
          <label>Bo User </label>
            <select className="custom-select form-control" name='bo_user' id="filter_boUser"  defaultValue={boUser_} > 
              <option value="">ALL</option>
              <option value="No Primary">No Primary</option> 
                {reportToList.map((data, i) => {
                return (
                  <option value={data.short_name} key={i}>{data.short_name}</option>
                )
              })}

            </select> 
          </div>


          <div class="form-group">
              <label>Branch</label>
              <select name="branch" id="filter_branch" class="form-control" autocomplete="off" defaultValue={branch_} >
                  <option value="">ALL</option>
                  {
                      officeBranchList.map((branch) => { 
                          return (  <option value={branch}>{branch}</option>  )
                      })   
                  }
              </select> 
          </div>
          

          <div className="form-group">
            <label>Profile Filter</label>
              <select className="form-control custom-select" name='ptype' /* onChange={(e) => profileFilter(e)} */ defaultValue={ptype}>
                  <option value="">Select</option>
                  <option value={""} >{"ALL"}</option>
                  <option value={"3"}>{"Active"}</option>
                  <option value={"2"}>{"Waitlisted"}</option>
                  <option value={"1"}>{"Testing"}</option>
                </select>
          </div>

          <div className="form-group">
            <label>Risk Profile Filter</label>
              <select className="form-control custom-select" name='risktype' /*  onChange={(e) => riskprofileFilter(e)} */ defaultValue={riskptype}>
                  <option value="">Select</option>
                  <option value={"0"}>{"ALL"}</option>
                  <option value={"1"}>{"Done"}</option>
                  <option value={"2"}>{"Not Done"}</option>
                </select>
          </div>

          <div className="form-group">
            <label>Plan Stage Filter</label>
            <select className="form-control custom-select" name='planstage' /* onChange={(e) => planStageFilter(e)} */ defaultValue={planStage}>
              <option value="">Select</option>
              <option value={"1"}>{"Goals Created Till Retirement"}</option>
              <option value={"2"}>{"Steps Completed"}</option>
              <option value={"3"}>{"Plan Completed"}</option>
              <option value={"4"}>{"Ready to Share"}</option>
              <option value={"5"}>{"Plan Shared"}</option>
              <option value={"6"}>{"Plan Re Shared"}</option>
              <option value={"7"}>{"Plan Freezed"}</option>
              <option value={"9"}>{"Execution Done"}</option>
            </select>
          </div>
              <br/>
          <div className="form-group">
            <small>Select a column name to apply the date range</small>
              <select name="daterange_column_name" id="daterange_column_name" defaultValue={daterange_column_name} className="form-control custom-select" >
                <option value="created_date">Created Date Range</option>
                <option value="shared_date">Shared Date Range</option> 
                <option value="freezed_date">Freezed Date Range</option> 
              </select>
          </div>
          <div className="form-group row"> 
              <div className="col-md-6">
                <input type="date" className="form-control " id="from_date" name="from_date" placeholder="Select From Date" defaultValue={from_date} />
              </div>
              <div className="col-md-6">
                <input type="date" className="form-control " id="to_date" name="to_date" placeholder="Select To Date" defaultValue={to_date} />
              </div> 
          </div>

          <div className="filter-buttons"> 
          <button type="submit" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary">
                Apply
            </button>
            <button type="button" className="d-inline-flex align-items-center justify-content-center btn w-100 btn-secondary" onClick={resetFilter} >
                Reset
            </button>
        </div>    
      </form>
      </SidebarPopupComponent>


      {
        (isActive === true && action === 'addTestProfile') && (<AddTestProfileSidebar setActive={setActive} setAction={setAction} />)
      }

      {
        (isActive === true && action === 'addDirectProfile') && (<AddDirectProfileSidebar setActive={setActive} setAction={setAction} />)
      }


      {
        (isActive === true && action === 'profileInterest') && (<ProfileInterestStatus setActive={setActive} setAction={setAction} profileId={profileId} />)
      }

    </NewTheme>
  )
}


const Status = (props) => {
  return props.testProfile === 1 ?
    <span className="badge bg-danger-light">Testing</span> :
    props.waitlist === 1 ? <span style={{ 'color': '#e55c09e0' }} className="badge bg-warning-light">Waitlisted</span> : <span className="badge bg-success-light">Active</span>
}