import React, { useState, useEffect } from 'react'
import axioInstance from '../../axiosInstance';
import { inWords } from '../../global';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

export default function DashboardTargets({ planCompleteData }) {

    const [value, setValue] = useState(5);

    const setIncomeGrow = (e) => {
        setValue(e);
    }

    // eslint-disable-next-line
    const [valueR, setValueR] = useState([30, 60]);
    // eslint-disable-next-line
    const [finalValue, setFinalValue] = React.useState(null);

    const [ongoingSaving, setOngoingSaving] = useState("");
    // eslint-disable-next-line
    const [cashflowOption, setCashflowOption] = useState("");

    const [fund, setFund] = useState("");
    const [shortYearly, setShortYearly] = useState("");
    const [actual, setActual] = useState('');
    // eslint-disable-next-line
    const [savingTarget, setSavingTarget] = useState("");
    const [totalSaving, setTotalSaving] = useState('');
    // eslint-disable-next-line
    const [customAmount, setCustomAmount] = useState("");

    const [selfAge, setSelfAge] = useState();
    const [retireAge, setRetireAge] = useState();
    const [lifeExpentancy, setLifeExpentancy] = useState();
    const [desireValue, setDesireValue] = useState(10);

    const [recommended, setRecommended] = useState();

    const [minSlider, setMinSlider] = useState();
    const [maxSlider, setMaxSlider] = useState();
    const [stepUp, setSetpUp] = useState(10000);

    const [minValue, set_minValue] = useState(0);
    const [maxValue, set_maxValue] = useState(0);

    /*const [dragActive, setDragActive] = useState(false);
    const handleInput = (e) => {

        if (minValue !== e.minValue) {
            setRetireAge(e.minValue);
        }

        if (maxValue !== e.maxValue) {
            setLifeExpentancy(e.minValue);
        }
        setDragActive(true);
        set_minValue(e.minValue);
        set_maxValue(e.maxValue);
    };*/

    useEffect(() => {

        /* 

        04-APRIL-2025 - Anoj Tambe - Commented axio call and used via Dahboard const Data

        const profile_id = sessionStorage.getItem('profile_id');
        axioInstance.post(`profile/summary`, {profile_id:profile_id}).then(
            (response, data) => {

                setOngoingSaving(response.data.InvestmentSummary.y_amount);
                setCashflowOption(response.data.profgoalSummary.cashflow_opt);
                setTotalSaving(parseInt(response.data.incomeSum.incomeSum) - parseInt(response.data.outflow));
                setFund(response.data.profgoalSummary.suggested_fund);
                setShortYearly(response.data.profgoalSummary.shortfall_yearly_with_increase);
                setActual(response.data.InvestmentSummary.c_amount !== null ? (parseInt(response.data.InvestmentSummary.c_amount) + parseInt(response.data.InvestmentSummary.cash_amount)) * response.data.profgoalSummary.shortfall_lumpsum : 0);
                setCustomAmount(response.data.profgoalSummary.custom_amount);

                if (response.data.profgoalSummary.cashflow_opt === "1") {
                    setSavingTarget((response.data.profgoalSummary.suggested_fund - (response.data.InvestmentSummary.c_amount - response.data.InvestmentSummary.cash_amount) * response.data.profgoalSummary.shortfall_lumpsum) / response.data.profgoalSummary.shortfall_yearly_with_increase);
                } else if (response.data.profgoalSummary.cashflow_opt === "2") {
                    setSavingTarget(parseInt(response.data.incomeSum.incomeSum) - parseInt(response.data.outflow));
                } else if (response.data.profgoalSummary.cashflow_opt === "3") {
                    setSavingTarget(response.data.profgoalSummary.custom_amount);
                }

                setLifeExpentancy(parseInt(response.data.assumption.life_expentancy));
                setRetireAge(parseInt(response.data.assumption.retire_age));


                set_maxValue(parseInt(response.data.assumption.life_expentancy));
                set_minValue(parseInt(response.data.assumption.retire_age));

                setSelfAge(parseInt(response.data.self_data.current_age));
                setValue(parseInt(response.data.assumption.income_grow));

                setRecommended((response.data.profgoalSummary.suggested_fund - (response.data.InvestmentSummary.c_amount) * response.data.profgoalSummary.shortfall_lumpsum) / response.data.profgoalSummary.shortfall_yearly_with_increase)

                setDesireValue(savingTarget > 0 ? (savingTarget / 100000).toFixed(2) : recommended);


                setSetpUp(Math.max(parseInt(totalSaving) + parseInt(ongoingSaving), recommended) <= 1000000 ? 10000 : Math.max(parseInt(totalSaving) + parseInt(ongoingSaving), recommended) > 2000000 ? 100000 : 50000);

                setMinSlider(Math.floor(Math.max(parseInt(totalSaving ? totalSaving : 0) + parseInt(ongoingSaving ? ongoingSaving : 0), recommended ? recommended : 0) / (stepUp * 100)) * (stepUp * 10));
                setMaxSlider((Math.floor(Math.max(parseInt(totalSaving ? totalSaving : 0) + parseInt(ongoingSaving ? ongoingSaving : 0), recommended ? recommended : 0) / (stepUp * 10)) * (stepUp)) * 20);

            });

            */

        setOngoingSaving(planCompleteData.InvestmentSummary.y_amount);
        setCashflowOption(planCompleteData.profgoalSummary.cashflow_opt);
        setTotalSaving(parseInt(planCompleteData.incomeSum.incomeSum) - parseInt(planCompleteData.outflow));
        setFund(planCompleteData.profgoalSummary.suggested_fund);
        setShortYearly(planCompleteData.profgoalSummary.shortfall_yearly_with_increase);
        setActual(planCompleteData.InvestmentSummary.c_amount !== null ? (parseInt(planCompleteData.InvestmentSummary.c_amount) + parseInt(planCompleteData.InvestmentSummary.cash_amount)) * planCompleteData.profgoalSummary.shortfall_lumpsum : 0);
        setCustomAmount(planCompleteData.profgoalSummary.custom_amount);

        if (planCompleteData.profgoalSummary.cashflow_opt === "1") {
            setSavingTarget((planCompleteData.profgoalSummary.suggested_fund - (planCompleteData.InvestmentSummary.c_amount - planCompleteData.InvestmentSummary.cash_amount) * planCompleteData.profgoalSummary.shortfall_lumpsum) / planCompleteData.profgoalSummary.shortfall_yearly_with_increase);
        } else if (planCompleteData.profgoalSummary.cashflow_opt === "2") {
            setSavingTarget(parseInt(planCompleteData.incomeSum.incomeSum) - parseInt(planCompleteData.outflow));
        } else if (planCompleteData.profgoalSummary.cashflow_opt === "3") {
            setSavingTarget(planCompleteData.profgoalSummary.custom_amount);
        }

        setLifeExpentancy(parseInt(planCompleteData.assumption.life_expentancy));
        setRetireAge(parseInt(planCompleteData.assumption.retire_age));


        set_maxValue(parseInt(planCompleteData.assumption.life_expentancy));
        set_minValue(parseInt(planCompleteData.assumption.retire_age));

        setSelfAge(parseInt(planCompleteData.self_data.current_age));
        setValue(parseInt(planCompleteData.assumption.income_grow));

        setRecommended((planCompleteData.profgoalSummary.suggested_fund - (planCompleteData.InvestmentSummary.c_amount) * planCompleteData.profgoalSummary.shortfall_lumpsum) / planCompleteData.profgoalSummary.shortfall_yearly_with_increase)

        setDesireValue(savingTarget > 0 ? (savingTarget / 100000).toFixed(2) : recommended);


        setSetpUp(Math.max(parseInt(totalSaving) + parseInt(ongoingSaving), recommended) <= 1000000 ? 10000 : Math.max(parseInt(totalSaving) + parseInt(ongoingSaving), recommended) > 2000000 ? 100000 : 50000);

        setMinSlider(Math.floor(Math.max(parseInt(totalSaving ? totalSaving : 0) + parseInt(ongoingSaving ? ongoingSaving : 0), recommended ? recommended : 0) / (stepUp * 100)) * (stepUp * 10));
        setMaxSlider((Math.floor(Math.max(parseInt(totalSaving ? totalSaving : 0) + parseInt(ongoingSaving ? ongoingSaving : 0), recommended ? recommended : 0) / (stepUp * 10)) * (stepUp)) * 20);



    }, [planCompleteData, fund, actual, shortYearly, savingTarget, recommended, stepUp, minSlider, totalSaving, ongoingSaving, minValue]);

    const UpdateAssumption = (value, assumptionType) => {


        const profile_id = sessionStorage.getItem('profile_id');

        let postData = '';
        if (assumptionType === "income_grow") {
            postData = { profile_id: profile_id, assumption: { income_grow: value } };
        } else if (assumptionType === "retire_age") {
            postData = { profile_id: profile_id, assumption: { retire_age: value } };
        } else if (assumptionType === "life_expentancy") {
            postData = { profile_id: profile_id, assumption: { life_expentancy: value } };
        }

        axioInstance.post(`profile-assumptions/update_assumption`, postData)
            .then(function (response) {

                const gpostData = { profile_id: profile_id };
                axioInstance.post(`goals/reset`, gpostData)
                    .then(function (response) {
                        axioInstance.post(`profile/summary-update`, { profile_id: profile_id }).then(
                            (response, data) => {
                                window.location.reload();
                            }
                        );
                    })
            })
    }

    // eslint-disable-next-line
    const cashflowOpt = (option, amount) => {

        let custom = option === 3 ? (amount * 100000) : amount;

        axioInstance.post(`profile/cashflow-option`, { profile_id: sessionStorage.getItem('profile_id'), 'cashflow_opt': option, 'custom_value': custom }).then(
            function (response) {
                window.location.reload();
            }
        )
    }

    const setDesireSlider = (e) => {
        setDesireValue(e);
    }

    const CashflowAfter = (e) => {
        cashflowOpt(3, e);
    }

    const growSliderAfter = (e) => {
        UpdateAssumption(e, 'income_grow')
    }

    const retireSliderAfter = (e) => {
        UpdateAssumption(e, 'retire_age')
    }


    const RetireExpRange = (e) => {
        console.log('RetireExpRange', e);

        if (minValue !== e[0]) {
            setRetireAge(parseInt(e[0]));
        }

        if (maxValue !== e[1]) {
            setLifeExpentancy(parseInt(e[1]));
        }

        set_minValue(parseInt(e[0]));
        set_maxValue(parseInt(e[1]));
    }

    const RetireExpSliderAfter = (e) => {

        UpdateAssumption(parseInt(e[0]), 'retire_age');
        UpdateAssumption(parseInt(e[1]), 'life_expentancy');

    }




    return (
        <>
            <div className="targetbar_outer mt25">
                <div className="targetbar_title">
                    <div className="font14 fw500 color182">Desired target</div>
                    <div>
                        <div className="tragetvalue font13 fw700 colorf47">
                            <span className="orangeDot mr5" />{desireValue > 0 ? Math.round(desireValue) + ' L' : inWords(desireValue)}
                        </div>
                    </div>
                </div>
                <div className="targetbar mt10">
                    <form className="target_range_bar">
                        <div className="form-group">

                            <Slider name={"desire"} className='mt15' min={minSlider / 100000} max={maxSlider / 100000} step={stepUp / 100000} onChange={e => setDesireSlider(e)} onAfterChange={(e) => CashflowAfter(e, 3)} value={desireValue} />

                        </div>
                    </form>
                    <div className="flex_center justify_center mt8">
                        <div className="font12 fw500 color9ba">{inWords(minSlider)}</div>
                        <div className="font12 fw500 color9ba">{inWords(maxSlider)}</div>
                    </div>

                </div>
                <div className="targetvalue_outer mt25">
                    <div className="targetvalue_prnt">
                        <div className="valuecount valuecount_gold font16 fw600">{inWords(ongoingSaving)}</div>
                        <div className="font12 fw500 color626">Yearly ongoing</div>
                    </div>
                    <div className="targetvalue_prnt" onClick={() => cashflowOpt(2, totalSaving)}>
                        <div className="valuecount valuecount_green font16 fw600">
                            {totalSaving ? inWords(parseInt(totalSaving)) : '----'}</div>
                        <div className="font12 fw500 color626">Ongoing + surplus</div>
                    </div>
                    <div className="targetvalue_prnt" onClick={() => cashflowOpt(1, recommended)}>
                        <div className="valuecount valuecount_blue font16 fw600"><div className="f-16 f-w-600">{inWords(recommended)}</div>
                        </div>
                        <div className="font12 fw500 color626">Recommended</div>
                    </div>
                </div>
                <div className="devider_line mt10" />
            </div>

            <div className="targetbar_outer mt25">
                <div className="targetbar_title">
                    <div className="font14 fw500 color182">Yearly investment increment</div>
                    <div>
                        <div className="tragetvalue font13 fw700">
                            {value}%
                        </div>
                    </div>
                </div>
                <div className="targetbar mt20">
                    <form className="target_range_bar">
                        <div className="form-group">


                            <Slider name={"income_grow"} className='mt15' min={0} max={20} step={1} onChange={e => setIncomeGrow(e)} onAfterChange={(e) => growSliderAfter(e, 3)} value={value} />



                        </div>
                    </form>
                    <div className="flex_center justify_center mt8">
                        <div className="font12 fw500 color9ba">0%</div>
                        <div className="font12 fw500 color9ba">20%</div>
                    </div>
                </div>
            </div>
            <div className="devider_line mt10" />

            <div className="targetbar_outer mt25">
                <div className="targetbar_title">
                    <div className="targetbar_title_left font14 fw500 color182">Active years & Retired years</div>
                    <div className="targetbar_title_right">
                        <div className="color353 font13 fw700">
                            {selfAge}
                        </div>
                        <div className="ml10 mr10 color353">-</div>
                        <div className="tragetvalue font13 fw700 color353">
                            {retireAge}
                        </div>
                        <div className="ml10 mr10 color353">-</div>
                        <div className="tragetvalue font13 fw700 color353">
                            {lifeExpentancy}
                        </div>
                    </div>
                </div>
                <div className="targetbar mt20">
                    <form className="target_range_bar">

                        <div className="form-group">

                            <Slider name={"retire_age"} className='mt15' min={selfAge + 1} max={100} step={1} onChange={e => setRetireAge(e)} onAfterChange={(e) => retireSliderAfter(e, 3)} value={retireAge} />

                            <div className="flex_center justify_center mt8">
                                <div className="font12 fw500 color9ba">{selfAge + 1}</div>
                                <div className="font12 fw500 color9ba">80</div>
                            </div>


                            <Slider className='mt50' range allowCross={false} min={selfAge + 2} max={100} step={1} value={[minValue, maxValue]} draggableTrack onChange={e => RetireExpRange(e)} onAfterChange={(e) => RetireExpSliderAfter(e)} pushable={false} />
                            <div className="flex_center justify_center mt8">
                                <div className="font12 fw500 color9ba">{selfAge + 2}</div>
                                <div className="font12 fw500 color9ba">100</div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}
