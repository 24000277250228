import React, { useState, useEffect, Fragment } from 'react'
import { inWords } from '../../global';
import axioInstance from '../../axiosInstance';

export default function DashboardFamilyNew({ verticalFamilyTab, setVerticalFamilyTab, planCompleteData, profileFamilyRiskGoals }) {


    const [childCounts, setChildCounts] = useState(0);
    const [adultCounts, setAdultCounts] = useState(0);
    const [familyCounts, setFamilyCounts] = useState(0);
    const [riskDetails, setRiskDetails] = useState([]);
    const [emergencyFund, setEmergencyFund] = useState(0);

    // eslint-disable-next-line
    const [selfTerm, setSelfTerm] = useState([]);
    // eslint-disable-next-line
    const [spouseTerm, setSpouseTerm] = useState([]);

    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');
        axioInstance.post(`profile-details/get-family-count`, { profile_id: profile_id }).then(
            (response, data) => {
                setChildCounts(response.data.childCounts);
                setAdultCounts(response.data.adultCounts);
                setFamilyCounts(response.data.familyCounts);
            });




        setRiskDetails(profileFamilyRiskGoals);

        let self = profileFamilyRiskGoals.riskgoals.find(product => (product.relation === "self" && product.goal_type === "Life Insurance"));
        setSelfTerm(self);


        let spouse = profileFamilyRiskGoals.riskgoals.find(product => product.relation === "spouse");
        setSpouseTerm(spouse ? spouse : false);

        /*

    axioInstance.post(`goals/get-risk-goals`, { profile_id: profile_id }).then(
        (response, data) => {
            setRiskDetails(response.data);

            let self = response.data.riskgoals.find(product => (product.relation === "self" && product.goal_type === "Life Insurance"));
            setSelfTerm(self);


            let spouse = response.data.riskgoals.find(product => product.relation === "spouse");
            setSpouseTerm(spouse ? spouse : false);

        });

        */

        /*
        04-APRIL-2025 - Anoj Tambe - Commented axio call and used via Dahboard const Data
        
        axioInstance.post(`profile/summary`, {profile_id:profile_id}).then(
        (response, data) => {

            setEmergencyFund(response.data.emergency_fund);
        });

        */

        setEmergencyFund(planCompleteData.emergency_fund);

    }, [planCompleteData, profileFamilyRiskGoals]);

    return (
        <Fragment>
            <div className="familycard p25">
                <div className="familycard_title">
                    <div className="color182 font14 fw600 familycard_title_left">Family members</div>
                    <div className="title_viewmore"><span className="font12 fw600 color0d6 pointer" onClick={() => setVerticalFamilyTab('members')}>View more <span className="bluerightarrow"></span></span> </div>
                </div>
                <div className="familycard_content mt20">
                    <div className="familycard_content_left">
                        <div className="fw500 colora5b font12"><span className="familyicon mr5" /> Members</div>
                        <div className="fw600 color162 font18">{familyCounts}</div>
                    </div>
                    <div className="familycard_content_mdl">
                        <div className="fw500 colora5b font12">Total Adults</div>
                        <div className="fw600 color162 font18">{adultCounts}</div>
                    </div>
                    <div className="familycard_content_right">
                        <div className="fw500 colora5b font12">Total Kids</div>
                        <div className="fw600 color162 font18">{childCounts}</div>
                    </div>
                </div>
            </div>
            <div className="familycard graybg p25">
                <div className="familycard_title">
                    <div className="color182 font14 fw600 familycard_title_left"><span className="insuranceicon mr5" /> Insurance</div>
                    <div className="title_viewmore"><span className="font12 fw600 color0d6 pointer" onClick={() => setVerticalFamilyTab('insurance')}>View more <span className="bluerightarrow"></span></span> </div>
                </div>
                <div className="familycard_content mt20">
                    <div className="familycard_content_left">
                        <div className="fw500 colora5b font12">Self Term</div>

                        <div className="fw600 color162 font18">{selfTerm ? (selfTerm.select_opt === "income_grow" ? inWords(selfTerm.suggestedoption2) : inWords(selfTerm.suggestedoption1)) : '00'}</div>
                    </div>
                    <div className="familycard_content_mdl">
                        <div className="fw500 colora5b font12">Spouse Term</div>
                        <div className="fw600 color162 font18">{spouseTerm ? (spouseTerm.select_opt === "income_grow" ? inWords(spouseTerm.suggestedoption2) : inWords(spouseTerm.suggestedoption1)) : '00'}</div>
                    </div>
                    <div className="familycard_content_right">
                        <div className="fw500 colora5b font12">Health</div>
                        <div className="fw600 color162 font18">{inWords(riskDetails.total_health_max_suggested)}</div>
                    </div>
                </div>
            </div>
            <div className="familycard  p25">
                <div className="familycard_title">
                    <div className="color182 font14 fw600 familycard_title_left"><span className="fundicon mr5" /> Emergency Funds</div>
                    <div className="title_viewmore"><span className="font12 fw600 color0d6 pointer" onClick={() => setVerticalFamilyTab('emergency')}>View more <span className="bluerightarrow"></span></span> </div>
                </div>
                <div className="familycard_content mt20">
                    <div className="familycard_content_left">
                        <div className="fw500 colora5b font12">Total Emergency fund</div>
                        <div className="fw600 color162 font18">{inWords(emergencyFund)}</div>
                    </div>
                </div>
            </div>



        </Fragment>
    )
}
