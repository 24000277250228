import React, { Fragment, useState } from 'react'
import DashboardTargets from './tabs/DashboardTargets'
import DashboardGoals from './tabs/DashboardGoals'
import DashboardCashflow from './tabs/DashboardCashflow'
import DashboardInvestment from './tabs/DashboardInvestment'
//import DashboardFamily from './tabs/DashboardFamily'
import DashboardFamilyNew from './tabs/DashboardFamilyNew'
import RiskProfile from '../RiskProfile'
import { Modal, ModalBody } from 'react-bootstrap';
import GenerateRiskProfile from './GenerateRiskProfile'
import ViewRiskProfile from './ViewRiskProfile'
import EditAssumptions from './EditAssumptions'
import EditRetireAssumptions from './EditRetireAssumptions'
import { useEffect } from 'react'
import { PopupModal } from "react-calendly";
import axioInstance from '../axiosInstance'

export default function DashboardLeft({ familyCount, assumptions, riskDescription, name, roi, roiOption, riskAssetMax, rightTab, setRightTab, verticalFamilyTab, setVerticalFamilyTab, planCompleteData }) {

    const [activeTab, setActiveTab] = useState('plan');

    const checkTab = (tab) => {
        setActiveTab(tab);

        if (tab === 'family') {
            setRightTab('family_r');
        } else {
            setRightTab('plan_r');
        }
    }

    const [show, setShow] = useState(false);
    const [isActive, setActive] = useState(false);
    const [action, setAction] = useState();

    const [url, setUrl] = useState("https://calendly.com/finnovate/financial-fitness-consultation?utm_medium=Plan&utm_campaign=Waitlisted_Plan&utm_source=WebPopup&utm_term=Bookacall_Popup"); // Default URL


    const handleClose = () => setShow(false);

    const toggleClass = (status, action) => {
        setActive(status);
        setAction(action);
    };

    const [profileFamilyRiskGoals ,setProfileFamilyRiskGoalsData] = useState([]);

    useEffect(() => {

        const profile_id = sessionStorage.getItem('profile_id');

        if (sessionStorage.getItem('admin_id') >= 1) {

            if (riskDescription === "Set" && sessionStorage.getItem('riskPopup') !== "1") {
                setTimeout(() => {
                    setMeetingshow(true);
                    sessionStorage.setItem('riskPopup', '1');
                }, 5000);
            } else {
                setMeetingshow(false);
            }

        } else {
            
            axioInstance.post(`profile/get`, { profile_id: profile_id }).then(
                (response, data) => {


                    const baseUrl = "https://calendly.com/finnovate/financial-fitness-consultation";
                    const params = new URLSearchParams({
                        utm_medium: "Plan",
                        utm_campaign: "Waitlisted_Plan",
                        utm_source: "WebPopup",
                        utm_term: "Bookacall_Popup",
                        name: response.data.full_name || "",
                        email: response.data.email || "",
                        a1: '+91' + response.data.phone || "" // Example parameter `a1`
                    });

                    setUrl(`${baseUrl}?${params.toString()}`);


                    if (response.data.waitlist === 1) {
                        setRiskshow(true);
                    } else {
                        setRiskshow(false);
                    }

                });
        }

        axioInstance.post(`goals/get-risk-goals`, { profile_id: profile_id }).then(
            (response, data) => {

                setProfileFamilyRiskGoalsData(response.data);

            });


    }, [riskDescription]);

    // eslint-disable-next-line
    const [popupIcon, setPopupIcon] = useState('deleteicon');
    // eslint-disable-next-line
    const [meetingshow, setMeetingshow] = useState(false);
    const [riskshow, setRiskshow] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const riskhandleClose = () => setMeetingshow(false);

    const whatsApp = () => {

        window.open("https://api.whatsapp.com/send?phone=919004316542&text=Hi%20I%20have%20completed%20the%20first%20cut%20of%20FinnFit%20plan", "_blank")
    }

    const handleCloseMeeting = () => {
        setRiskshow(false); // Close the modal
        // Set a timeout to reopen the modal after 1 minute
        setTimeout(() => {
            setRiskshow(true);
        }, 60000); // 60000ms = 1 minute
    };

    return (
        <Fragment>
            <div className="dashboard_left pb20">
                <div className="dashboard_left_title font16 fw500 color182">{name} financial input</div>
                <div className="categoryTabs_outer mt20">
                    <li onClick={() => toggleClass(true, riskDescription !== 'Set' ? 'viewRisk' : 'geneRisk')}>
                        <div className="categoryTab font12 fw500 color353"><span className="risk mr5" /> {riskDescription} Risk ROI<span className="font12 fw600 color06d ml5">{roi}%</span></div>
                    </li>

                    <li onClick={() => toggleClass(true, 'assumption')}>
                        <div className="categoryTab font12 fw500 color353"><span className="family_icon mr5" /> Assumptions </div>
                    </li>

                    {
                        sessionStorage.getItem('admin_role') === "0" && (<li onClick={() => toggleClass(true, 'retire_assumption')}>
                            <div className="categoryTab font12 fw500 color353"><span className="family_icon mr5" /> Retire Assumptions </div>
                        </li>)
                    }

                    <li onClick={() => checkTab('family')}>
                        <div className="categoryTab font12 fw500 color353"><span className="family_icon mr5" /> Family of {familyCount}</div>
                    </li>
                </div>


                <div className="db_maintab_outer mt25">
                    <div className="db_maintab_wpr">
                        <nav className="font13">
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <a className={activeTab === "plan" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-plan-tab" data-toggle="tab" href="#nav-plan" role="tab" aria-controls="nav-plan" aria-selected="true" onClick={() => checkTab('plan')}>Plan</a>

                                <a className={activeTab === "goals" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-goal-tab" data-toggle="tab" href="#nav-goal" role="tab" aria-controls="nav-goal" aria-selected="false" onClick={() => checkTab('goals')}>Goals</a>

                                <a className={activeTab === "cashflow" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-cashflow-tab" data-toggle="tab" href="#nav-cashflow" role="tab" aria-controls="nav-cashflow" aria-selected="false" onClick={() => checkTab('cashflow')}>Cashflow</a>

                                <a className={activeTab === "investment" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-investments-tab" data-toggle="tab" href="#nav-investments" role="tab" aria-controls="nav-investments" aria-selected="false" onClick={() => checkTab('investment')}>Investments</a>

                                <a className={activeTab === "family" ? "nav-item nav-link  active" : "nav-item nav-link"} id="nav-family-tab" data-toggle="tab" href="#nav-family" role="tab" aria-controls="nav-family" aria-selected="false" onClick={() => checkTab('family')}>Family</a>
                            </div>
                        </nav>
                    </div>
                    <div className="tab-content" id="nav-tabContent">
                        <div className={activeTab === "plan" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-plan" role="tabpanel" aria-labelledby="nav-plan-tab">
                            {activeTab === 'plan' && <DashboardTargets planCompleteData={planCompleteData} />}
                        </div>
                        <div className={activeTab === "goals" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-goal" role="tabpanel" aria-labelledby="nav-goal-tab">
                            {activeTab === 'goals' && <DashboardGoals planCompleteData={planCompleteData} />}
                        </div>
                        <div className={activeTab === "cashflow" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-cashflow" role="tabpanel" aria-labelledby="nav-cashflow-tab">
                            {activeTab === 'cashflow' && <DashboardCashflow planCompleteData={planCompleteData} />}
                        </div>
                        <div className={activeTab === "investment" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-investments" role="tabpanel" aria-labelledby="nav-investments-tab">
                            {activeTab === 'investment' && <DashboardInvestment planCompleteData={planCompleteData} />}
                        </div>
                        <div className={activeTab === "family" ? "tab-pane fade show active" : "tab-pane fade "} id="nav-family" role="tabpanel" aria-labelledby="nav-family-tab">
                            {activeTab === 'family' && <DashboardFamilyNew verticalFamilyTab={verticalFamilyTab} setVerticalFamilyTab={setVerticalFamilyTab} planCompleteData={planCompleteData} profileFamilyRiskGoals={profileFamilyRiskGoals}/>}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <ModalBody>
                    <div className="p25">
                        <h1 className='font20 fw600'>Set your Risk Profile</h1>
                        <div className="font13 color495 ">These are not even goals, but ust haves to financially safe life</div>
                        <div className="mt25">
                            <RiskProfile />
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {
                (isActive === true && action === 'viewRisk') && (<ViewRiskProfile toggleClass={toggleClass} setActive={setActive} setAction={setAction} riskDescription={riskDescription} />)
            }

            {
                (isActive === true && action === 'geneRisk') && (<GenerateRiskProfile toggleClass={toggleClass} setActive={setActive} setAction={setAction} riskDescription={riskDescription} />)
            }

            {
                (isActive === true && action === 'assumption') && (<EditAssumptions toggleClass={toggleClass} setActive={setActive} setAction={setAction} roiOption={roiOption} riskAssetMax={riskAssetMax} />)
            }

            {
                (isActive === true && action === 'retire_assumption') && (<EditRetireAssumptions toggleClass={toggleClass} setActive={setActive} setAction={setAction} />)
            }

            <Modal show={meetingshow} onHide={riskhandleClose} className='modal model-sm' dialogClassName='modal-container' keyboard centered>
                <ModalBody>
                    <div className="p20">
                        <div className="text-center">
                            <span className={popupIcon} />
                        </div>
                        <div className="text-center mt10 color1e3 fw600 font18">
                            {"Risk profile"}
                        </div>
                        <div className="text-center mt10 color485 fw500 font14">
                            {"Your Risk Profile is Incomplete"}
                        </div>
                        <div className="smallbtn mt20">
                            <button type="button" className="btn btn-outline-secondary" onClick={riskhandleClose}>Cancel</button>
                            <button type="button" className={popupIcon !== "successIcon" ? "btn btn-danger" : "btn btn-success"} onClick={() => { riskhandleClose(); toggleClass(true, 'geneRisk') }}>Continue to Risk</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>


            <Modal show={riskshow} size="lg" onHide={handleCloseMeeting} backdrop="static" dialogClassName='modal-container' keyboard centered>
                <ModalBody>
                    <div className="p20">

                        {/* Close button in the top-right corner */}
                        <button
                            title={"close popup"}
                            type="button"
                            className="close-button"
                            onClick={handleCloseMeeting}
                            //onClick={() => { setRiskshow(false); setIsOpen(false); }}
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "transparent",
                                border: "none",
                                fontSize: "24px",
                                color: "#000",
                                cursor: "pointer"
                            }}
                        >
                            <i class="fa fa-close fa-1x"></i>
                        </button>

                        <div className="text-left">
                            <img src='images/uim_schedule.png' alt='shedule' width={"80"} />
                        </div>
                        <div className="text-left mt40">
                            <h1 className='font24 fw700 color384'>Book your 1st consultation now</h1>
                        </div>
                        <div className="text-left mt40 color485 fw500 font17">
                            <strong>{"Congratulations"}</strong>{" on taking the first step. You have now unlocked your first complimentary session with our FinnFit Expert. This consultation is designed to assist you in completing and thoroughly explaining your Financial Fitness Plan."}
                        </div>
                        <div className="text-left mt40 color485 fw500 font17">
                            <strong>{"Did you know?"}</strong> <br />
                            {"Those who build Financial Fitness Plans are 90% more likely to reach their life goals!"}
                        </div>

                        <div className="text-center smallbtn popupConsultBtn mt30">
                            <button type="button" className={"btn btnConsultation"} onClick={() => { setRiskshow(false); setIsOpen(true); }}><i class="fa-regular fa-calendar btnConsultationFa"></i> Book a Call</button>
                            <button type="button" className={"btn btnConsultationWA"} onClick={() => { whatsApp() }}><i class="fab fa-whatsapp btnConsultationFa"></i> WhatsApp Us</button>
                        </div>

                        <div className="text-left mt40 color485 fw500 font17">
                            {"No charges what-so-ever"}
                        </div>


                    </div>
                </ModalBody>
            </Modal>



            <PopupModal
                url={url}
                onModalClose={() => { setIsOpen(false); setRiskshow(true) }}
                open={isOpen}
                rootElement={document.getElementById("root")}
            />

        </Fragment>
    )
}
